<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title KPI Rules Log
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'kpiRuleLog'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group
              label Dates
              date-range-picker(:localeData="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
                :auto-apply="true", v-model="filters.dateRange", :time-picker="true", :time-picker24-hour="true",
                :time-picker-increment="1")
            //.form-group
              label Timezone
              select.form-control(v-model="filters.timezone")
                option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}
            select-offer(v-model="filters.offer_id")
            select-placement(v-model="filters.placement_id")
            select-publisher(v-model="filters.publisher_id")
            //.form-group
              label Offer ID
              input.form-control.input-search(type="text", placeholder="", v-model="filters.offer_id")
            .form-group
              label Rule
              select.form-control(v-model="filters.rule_id", @change="loadData()")
                option(value="0") - All -
                option(v-for="o in ruleOptions", :value="o.v") {{ o.t }}
            .form-group
              label Assignee
              select.form-control(v-model="filters.user_id", @change="loadData()")
                option(value="0") - All -
                option(v-for="o in userOptions", :value="o.v") {{ o.t }}
            //.form-group.no-label
              button.btn.btn-primary(type="submit") Go
            .form-group.no-label
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download.mr-1
                    | Export

        p.clearfix &nbsp;

        .ov-table-wrapper
          paginate(:paginator="paginate", @update-page-size="loadData")
          table.table.table-bordered.table-rule-log
            thead
              tr
                th Log ID
                th Advertiser
                th Offer
                th Publisher
                th Placement
                th Rule
                th Description
                //th Assignee
                th Offer Status
                th Offer Comments
                th Date
                th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="11") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td
                  entity(type="advertiser", :id="r.offer.advertiser.id", :name.sync="r.offer.advertiser.name")
                td
                  entity(type="offer", :id="r.offer_id", :name.sync="r.offer.name", , @click="appendEntity(r,'offer')")
                td
                  entity(v-if="r.publisher", type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name", , @click="appendEntity(r,'publisher')")
                td
                  entity(v-if="r.placement", type="placement", :id="r.placement.id", :name.sync="r.placement.name", , @click="appendEntity(r,'placement')", :data="r.placement")
                td
                  entity(type="rule", :id="r.rule_id", :name.sync="r.rule.name", @click="appendEntity(r,'rule')")
                td(style="white-space: break-spaces") {{ r.body }}
                //td
                  b-avatar(v-if="r.user_id", :variant="r.user_variant", :text="r.user.name_abv", v-b-tooltip.hover.right, :title="r.user.name")
                td.col-status
                  b-dd(:text="r.offer.status", :class="'status-'+r.offer.status", :disabled="!!r.offer.reserved")
                    b-dropdown-item(v-for="o in offerStatusOptions", v-bind:key="o.v", :class="'option-'+o.v", @click="updateStatus(r.offer, o.v)") {{ o.t }}
                td.edit-inline
                  quick-edit(field="comments", :r="r.offer", @on-save="updateField", :wrapped="true", type="textarea")

                td {{ r.created_at }}
                td.actions
                  router-link.btn.btn-action-info(:to="{name:'reports', query: {offer_id:r.offer_id}}", v-b-tooltip.hover.bottom, title="Statistics")
                    i.la.la-bar-chart



          paginate(:paginator="paginate", @update-page-size="loadData")
</template>

<style lang="scss">
table.table-rule-log {
  tbody {
    tr {
      td {
        padding: 3px;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'KpiRuleLog',
  async beforeRouteEnter(to, from, next) {
    let userOptions = await Vue.ovData.user.getOptions(false, ['admin', 'am']);
    let ruleOptions = await Vue.ovData.rule.getOptions(true);
    next((vm) => {
      vm.userOptions = userOptions;
      vm.ruleOptions = ruleOptions;
    });
  },
  data() {
    const defaultFilters = {
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      user_id: 0,
      rule_id: 0,
      timezone: 0,
      dateRange: {
        startDate: moment(
          moment()
            .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
            .format('DD/MM/YYYY') + ' 00:00',
          'DD/MM/YYYY HH:mm',
        ),
        endDate: moment(
          moment()
            .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
            .format('DD/MM/YYYY') + ' 23:59',
          'DD/MM/YYYY HH:mm',
        ),
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      userOptions: [],
      ruleOptions: [],
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      timeZoneOptions: Vue.ovData.general.timezoneOptions,
      datePickerOptions: {
        timePicker: true,
        timePicker24Hour: true,
        locale: {
          format: 'DD/MM/YYYY HH:mm',
        },
        alwaysShowCalendars: true,
        autoApply: true,
        ranges: {},
      },
      variantOptions: [
        'secondary',
        'primary',
        'dark',
        'light',
        'success',
        'danger',
        'warning',
        'info',
      ],
      records: [],
      offerStatusOptions: [
        { v: 'all', t: 'All' },
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // {v: 'ended', t: 'Ended'}
      ],
    };
  },
  methods: {
    updateRanges() {
      let tz = this.filters.timezone;
      let localOffset = new Date().getTimezoneOffset() / 60;
      let tzGap = -localOffset - tz;

      this.datePickerOptions.ranges = {
        'This Hour': [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Hour': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Today: [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Yesterday: [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 7 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 30 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'This Month': [
          moment(
            moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Month': [
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .startOf('month')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .endOf('month')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
      };
    },

    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        user_id: this.filters.user_id,
        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),
        rule_id: this.filters.rule_id,
        timezone: this.filters.timezone,
        date_start: moment(this.filters.dateRange.startDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
        date_end: moment(this.filters.dateRange.endDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
      };
      try {
        let resp = await this.$ovReq.get('notification/getRuleList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
          this.records.forEach((r) => {
            if (r.user_id) {
              r.user_variant = this.variantOptions[r.user_id % this.variantOptions.length];
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    updateStatus(offer, v) {
      offer._status = v;
      this.updateField(offer, 'status');
    },
    async updateField(offer, field) {
      let data = {
        id: offer.id,
        key: field,
        field: field,
        val: offer['_' + field],
        value: offer['_' + field],
      };
      if (Vue.ovData.offer._booleans.indexOf(field) > -1) {
        data.val = data.val ? 1 : 0;
      }
      try {
        let resp = await this.$ovReq.post('offer/updateField', data);
        offer['_edit_' + field] = false;
        offer[field] = resp.entity[field];
        this.records.forEach((r) => {
          if (r.offer && r.offer.id === offer.id) {
            r.offer[field] = resp.entity[field];
          }
        });
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },

    appendEntity(record, entityType) {
      switch (entityType) {
        case 'offer':
          this.filters.offer_id.push({
            id: record.offer_id,
            name: `[${record.offer_id}] ${record.offer.name}`,
          });
          break;
        case 'rule':
          this.filters.rule_id = record.rule_id;
          break;
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    this.updateRanges();
    let intv = setInterval(() => {
      this.updateRanges();
    }, 60 * 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(intv);
    });
  },
  created() {
    this.loadData();
  },
};
</script>
