<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title Activity Log
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'activityLog'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group.fg-advertiser
              label User(s)
              v-select.multiple(:options="userOptions", v-model="filters.user_id", label="t", :reduce="o => o.v")
            .form-group
              label Dates
              date-range-picker(:localeData="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
                :auto-apply="true", v-model="filters.dateRange", :time-picker="true", :time-picker24-hour="true",
                :time-picker-increment="1")
            .form-group
              label Timezone
              select.form-control(v-model="filters.timezone")
                option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}
            .form-group
              label Advertiser ID
              input.form-control.input-search(type="text", placeholder="", v-model="filters.advertiser_id")
            .form-group
              label Publisher ID
              input.form-control.input-search(type="text", placeholder="", v-model="filters.publisher_id")
            .form-group
              label Offer ID
              input.form-control.input-search(type="text", placeholder="", v-model="filters.offer_id")
            .form-group
              label Placement ID
              input.form-control.input-search(type="text", placeholder="", v-model="filters.placement_id")
            .form-group
              label Action
              select.form-control(v-model="filters.action", @change="loadData()")
                option(v-for="o in typeOptions", :value="o.v") {{ o.t }}
            .form-group.no-label
              button.btn.btn-primary(type="submit") Go

        p.clearfix &nbsp;

        .ov-table-wrapper
          paginate(:paginator="paginate", @update-page-size="loadData")
          table.table.table-bordered
            thead
              tr
                th ID
                th User
                th Action
                th Descr.
                th Changes
                th Advertiser
                th Offer
                th Publisher
                th Placement
                th Created
                th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="11") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td {{ r.user ? r.user.name : r.user_id }}
                td {{ r.action }}
                td {{ r.description }}
                td
                  div(v-if="r.data && r.data.changes")
                    div(v-for="c in r.data.changes") <strong>{{ c.f }}:</strong> {{ c.o || c.old }} => {{ c.n || c.new }}
                td {{ r.advertiser_id }}
                td {{ r.offer_id }}
                td {{ r.publisher_id }}
                td {{ r.placement_id }}
                td {{ r.created_at }}
                td.actions
                  | &nbsp;
                  a.btn.btn-sm.btn-secondary(href="javascript:void(0)", @click="deleteRecord(r)")
                    i.la.la-trash
          paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'ActivityLog',
  async beforeRouteEnter(to, from, next) {
    let userOptions = await Vue.ovData.user.getOptions(false, ['admin', 'am']);
    next((vm) => {
      vm.userOptions = userOptions;
    });
  },
  data() {
    const defaultFilters = {
      offer_id: '',
      placement_id: '',
      advertiser_id: '',
      publisher_id: '',
      user_id: 0,
      status: -1,
      action: 'all',
      keyword: '',
      timezone: 0,
      dateRange: {
        startDate: moment(
          moment()
            .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
            .format('DD/MM/YYYY') + ' 00:00',
          'DD/MM/YYYY HH:mm',
        ),
        endDate: moment(
          moment()
            .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
            .format('DD/MM/YYYY') + ' 23:59',
          'DD/MM/YYYY HH:mm',
        ),
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      userOptions: [],
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      timeZoneOptions: Vue.ovData.general.timezoneOptions,
      datePickerOptions: {
        timePicker: true,
        timePicker24Hour: true,
        locale: {
          format: 'DD/MM/YYYY HH:mm',
        },
        alwaysShowCalendars: true,
        autoApply: true,
        ranges: {},
      },
      statusOptions: [
        { v: -1, t: 'All' },
        { v: 0, t: '0' },
        { v: 1, t: '1' },
        { v: 4, t: '4' },
      ],
      typeOptions: [
        { v: 'all', t: 'All' },
        { v: 'offer.edit', t: 'offer.edit' },
        { v: 'offer.create', t: 'offer.create' },
        { v: 'offer.status', t: 'offer.status' },
        { v: 'placement.edit', t: 'placement.edit' },
        { v: 'placement.create', t: 'placement.create' },
        { v: 'placement.status', t: 'placement.status' },
      ],
      records: [],
    };
  },
  methods: {
    updateRanges() {
      let tz = this.filters.timezone;
      let localOffset = new Date().getTimezoneOffset() / 60;
      let tzGap = -localOffset - tz;

      this.datePickerOptions.ranges = {
        'This Hour': [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Hour': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Today: [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Yesterday: [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 7 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 30 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'This Month': [
          moment(
            moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Month': [
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .startOf('month')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .endOf('month')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
      };
    },

    async deleteRecord(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm('Are you sure?');
      if (!confirmed) {
        return;
      }
      try {
        let resp = await this.$ovReq.post('activityLog/delete', { id: r.id });
        console.log(resp);
        this.$ovNotify.success('Record has been removed');
        this.loadData();
      } catch (e) {
        console.error(e);
      }
    },

    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        user_id: this.filters.user_id,
        offer_id: this.filters.offer_id,
        advertiser_id: this.filters.advertiser_id,
        publisher_id: this.filters.publisher_id,
        placement_id: this.filters.placement_id,
        action: this.filters.action,
        timezone: this.filters.timezone,
        date_start: moment(this.filters.dateRange.startDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
        date_end: moment(this.filters.dateRange.endDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
      };
      try {
        let resp = await this.$ovReq.get('activityLog/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  mounted() {
    this.updateRanges();
    let intv = setInterval(() => {
      this.updateRanges();
    }, 60 * 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(intv);
    });
  },
  created() {
    this.loadData();
  },
};
</script>
